/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'swiper/css/bundle';

@import './theme/variables.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    user-select: text;
}

ion-content {
    --background: #;
}

.header-md::after {
    background-image: none;
}

.media-modal {
    --height: 75%;
    --width: 75%;
}

ckeditor {
    width: 100%;
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}

.ck.ck-content ul,
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

@layer components {
    .text-h1-grey {
        @apply font-bold;
        font-size: 22px;
        color: #b7bbc8;
    }

    .small-header {
        @apply uppercase text-[11px] text-[#828282] font-bold;
    }

    .medium-header {
        @apply uppercase text-sm text-[#828282] font-bold;
    }
}

@layer base {
    .ellipsis {
        @apply overflow-ellipsis overflow-hidden whitespace-nowrap;
    }
}

.action-sheet-group {
    width: 97%;
    position: absolute;
    left: 1.5%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: var(--ion-safe-area-bottom);
}

table {
    @apply rounded-md w-full;
}

table tr:last-child td /*To remove the last border*/ {
    border-bottom: 0 solid;
}

.mat-header-row {
    @apply bg-[#f0f1f3];
}

tbody > tr {
    @apply hover:bg-[#f0f1f3] cursor-pointer;
}

ion-button {
    @apply normal-case;
}

.preview-modal {
    --min-width: 100%;
    --min-height: 100%;
}

ol {
    padding-left: 20px !important;
    list-style-type: decimal;
}

ul {
    padding-left: 20px !important;
    list-style-type: inherit;
}
